import "@/@fake-db/db";
// Global Components
import "./global-components";
// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "flatpickr/dist/flatpickr.css";

import BootstrapVue, { ModalPlugin, ToastPlugin } from "bootstrap-vue";

import App from "./App.vue";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import moment from "moment";
import ProgressBar from "vuejs-progress-bar";
window.axios = import("@/libs/axios");

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(vSelect);
Vue.use(ProgressBar);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
window.moment = moment;

const pharmacyInfo = Vue.observable({ pharmacyInfo: {} });

Object.defineProperty(Vue.prototype, "$pharmacyInfo", {
  get() {
    return pharmacyInfo.pharmacyInfo;
  },

  set(value) {
    pharmacyInfo.pharmacyInfo = value;
  },
});

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.event);
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
