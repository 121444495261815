<template>
  <div
    v-if="uploadFileStore.isProcess&&uploadFileStore.uploadStatus"
    class="global-upload d-print-none"
  >
    <div class="cs-card-cn">
      <feather-icon @click="handleClose" class="close-icon" icon="XIcon" size="18" />
      <b-card-actions border-variant="primary" no-body title="Tải lên" action-collapse>
        <div class="d-flex align-items-center justify-content-between my-1 flex-wrap">
          <span class="mr-2 flex-1">
            {{ uploadFileStore.title }}
            <p v-if="uploadFileStore.uploadStatus.value===1">
              Tiến trình:
              <span
                class="mb-0 font-bold text-primary"
              >{{ ` ${uploadFileStore.process||0}%` }}</span>
            </p>
          </span>
          <span>
            <!-- <progress-bar
              class="cs-progress"
              :hideText="true"
              v-if="uploadFileStore.uploadStatus.value===UPLOAD_STATUS[0].value"
              :options="options"
              :value="uploadFileStore.process"
            />-->
            <feather-icon
              :icon="uploadFileStore.uploadStatus.icon"
              :class="uploadFileStore.uploadStatus.class"
              size="24"
            />
          </span>
        </div>
      </b-card-actions>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UPLOAD_STATUS } from "../../utils/constant";

const options = {
  text: {
    color: "#000",
    shadowEnable: false,
    shadowColor: "#000000",
    fontSize: 12,
    fontFamily: "Helvetica",
    dynamicPosition: true,
    hideText: true
  },
  progress: {
    color: "#2dbd2d",
    backgroundColor: "#C0C0C0"
  },
  layout: {
    height: 24,
    width: 24,
    verticalTextAlign: 25,
    horizontalTextAlign: 5,
    strokeWidth: 15,
    progressPadding: 0,
    type: "circle"
  }
};

export default {
  name: "UploadFile",
  components: { BCardActions, ToastificationContent },
  data() {
    return { UPLOAD_STATUS, options, timeInterval: null };
  },
  computed: {
    ...mapState({
      uploadFileStore: state => state.uploadFileStore
    }),
    dataList() {
      return this.uploadFileStore.dataList;
    }
  },
  watch: {
    dataList: {
      deep: true,
      handler() {
        if (
          this.uploadFileStore.isProcess &&
          this.uploadFileStore.dataList?.length
        ) {
          this.handleUploadFile(this.uploadFileStore.dataList);
        }
      }
    }
  },
  created() {
    const progressInfo = JSON.parse(localStorage.getItem("progressInfo"));

    if (progressInfo) {
      this.handleProcessUpload(progressInfo.progressId);
      this.handleGetProgressInfo(progressInfo);
    }
  },
  mounted() {
    if (
      this.uploadFileStore.isProcess &&
      this.uploadFileStore.dataList?.length
    ) {
      this.handleUploadFile(this.uploadFileStore.dataList);
    }
  },
  methods: {
    handleClose() {
      this.$store.commit("uploadFileStore/updateIsProgress", false);
    },
    //Case FE handle upload
    async handleUploadFile(data) {
      const self = this;

      try {
        if (data?.length > 200) {
          const upload = async () => {
            // Slice data
            for (let i = 0; i < Math.ceil(data.length / 200); i++) {
              const sliceData = data.slice(i * 200, i * 200 + 200);

              await self.$store
                .dispatch("uploadFileStore/importMedicines", {
                  data: sliceData,
                  pharma_id: self.uploadFileStore.pharma_id
                })
                .then(response => {
                  const number_error = response.data.number_error?.length || 0;

                  self.$store.commit("uploadFileStore/updateTotalResponse", {
                    number_success:
                      self.uploadFileStore.totalResponse.number_success +
                      response.data.number_success,
                    number_error:
                      self.uploadFileStore.totalResponse.number_error +
                      number_error,
                    number_duplicate:
                      self.uploadFileStore.totalResponse.number_duplicate +
                        response.data?.number_duplicate?.length || 0
                  });

                  //calc process percent
                  const dataPercent =
                    ((i + 1) / Math.ceil(data.length / 200)) * 100;

                  self.$store.commit(
                    "uploadFileStore/updateProcess",
                    dataPercent
                  );
                });
            }
          };

          await upload();
        } else {
          const response = await self.$store.dispatch(
            "uploadFileStore/importMedicines",
            { data, pharma_id: self.uploadFileStore.pharma_id }
          );
          self.$store.commit("uploadFileStore/updateTotalResponse", {
            number_success:
              self.uploadFileStore.totalResponse.number_success +
              response.data.number_success,
            number_error:
              self.uploadFileStore.totalResponse.number_error +
                response.data.number_error?.length || 0,
            number_duplicate:
              self.uploadFileStore.totalResponse.number_duplicate +
                response.data?.number_duplicate?.length || 0
          });
        }

        const {
          number_success,
          number_duplicate,
          number_error
        } = self.uploadFileStore.totalResponse;

        const labelSuccess = number_success
          ? `${number_success} danh mục thành công`
          : "";
        const labelDuplicate = number_duplicate
          ? `${number_duplicate} danh mục đã tồn tại`
          : "";
        const labelError = number_error ? `${number_error} danh mục lỗi` : "";

        let variant = "success";
        let icon = "CheckCircleIcon";
        let statusInfo = UPLOAD_STATUS[1];

        if (number_success === 0 && number_duplicate === 0 && number_error) {
          variant = "danger";
          icon = "AlertTriangleIcon";
          statusInfo = UPLOAD_STATUS[2];
        } else if (
          number_success === 0 &&
          number_error === 0 &&
          number_duplicate
        ) {
          variant = "warning";
          icon = "AlertTriangleIcon";
          statusInfo = UPLOAD_STATUS[2];
        }
        self.$store.commit("uploadFileStore/updateStatusInfo", statusInfo);

        self.$toast({
          component: ToastificationContent,
          props: {
            title: `Tải lên có ${labelSuccess} ${labelDuplicate} ${labelError}`,
            icon,
            variant
          }
        });

        // Call refresh data
        self.uploadFileStore.callback();
        self.$store.commit("uploadFileStore/setCallback", null);
      } catch (error) {
        self.$store.commit(
          "uploadFileStore/updateStatusInfo",
          UPLOAD_STATUS[2]
        );

        console.log(error);
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra trong quá trình tải tệp lên",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      } finally {
        // Refresh data
        self.$store.commit("uploadFileStore/setDataList", []);
        self.$store.commit("uploadFileStore/updateTotalResponse", {
          number_success: 0,
          number_error: 0,
          number_duplicate: 0
        });
        self.$store.commit("uploadFileStore/updateProcess", 0);
      }
    },

    //Case BE handle upload
    async handleGetProgressInfo(progressInfo) {
      this.$store.commit("uploadFileStore/updateIsProgress", true);
      this.$store.commit("uploadFileStore/updateStatusInfo", UPLOAD_STATUS[0]);
      this.$store.commit(
        "uploadFileStore/updateTitle",
        progressInfo.title || ""
      );

      // Get process percent loop 5s
      this.timeInterval = setInterval(async () => {
        await this.handleProcessUpload(progressInfo.progressId);
      }, 5000);
    },
    async handleProcessUpload(progressId) {
      const self = this;

      try {
        const response = await self.$store.dispatch(
          "uploadFileStore/getProgressUpload",
          progressId
        );

        const responseData = response.data;
        const uploadPercent = Math.ceil(
          (responseData?.current_value / responseData?.total) * 100
        );
        self.$store.commit("uploadFileStore/updateProcess", uploadPercent);

        if (Number(responseData.current_status) === 2) {
          clearInterval(self.timeInterval);

          this.$store.commit(
            "uploadFileStore/updateStatusInfo",
            UPLOAD_STATUS[1]
          );
          // self.uploadFileStore.callback();
          // self.$store.commit("uploadFileStore/setCallback", null);

          self.$store.commit("uploadFileStore/updateProcess", 0);
          localStorage.removeItem("progressInfo");

          self.$toast({
            component: ToastificationContent,
            props: {
              title: `Nhập thành công ${responseData.total} danh mục`,
              icon: "CheckCircleIcon",
              variant: "success"
            }
          });
        }
      } catch (error) {
        console.log(error);
        clearInterval(self.timeInterval);
        localStorage.removeItem("progressInfo");
        self.$store.commit(
          "uploadFileStore/updateStatusInfo",
          UPLOAD_STATUS[2]
        );
        self.$store.commit("uploadFileStore/updateProcess", 0);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi khi nhập Tồn đầu kỳ",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.global-upload ::v-deep {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 1000000;

  .card {
    padding: 8px;
    width: 300px;

    * {
      font-size: 16px;
    }
  }

  .cs-card-cn {
    position: relative;
    .close-icon {
      position: absolute;
      top: 15px;
      right: 6px;
      color: #494949 !important;
      cursor: pointer;
      z-index: 1;
    }

    .feather-chevron-down {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .card-header {
    padding: 4px;
    font-size: 18px !important;
    border-bottom: 1px solid #797979;
  }

  .upload-loading {
    animation: loading 2s ease-in-out;
    animation-fill-mode: alternate;
    animation-iteration-count: infinite;
  }

  // .cs-progress {
  //   position: absolute;
  //   top: -52px;
  //   left: 0;
  // }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>