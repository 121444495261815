import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUseTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('go/pharma/pharma_use_route', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUseType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`go/pharma/pharma_use_route/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUseType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`go/pharma/pharma_use_route/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUseType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('go/pharma/pharma_use_route', data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUseType(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`go/pharma/pharma_use_route/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
