import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import authenticate from "./authenticate";
import phamarProduct from "./phamarProduct";
import productBrand from "./productBrand";
import productCategory from "./productCategory";
import productType from "./productType";
import inventoryManager from "./inventoryManager";
import receiving from "./receiving";
import saling from "./saling";
import phamarProductInventory from "./phamarProductInventory";
import pharmaUseType from "./pharmaUseType";
import reportStore from "./reportStore";
import uploadFileStore from "./uploadFileStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authenticate,
    phamarProduct,
    productBrand,
    productCategory,
    productType,
    inventoryManager,
    receiving,
    saling,
    phamarProductInventory,
    pharmaUseType,
    reportStore,
    uploadFileStore,
  },
  strict: process.env.DEV,
});
