import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      orgId: 'org 1',
      wsId: 'ws 1',
      name: 'inventory 1',
      description: 'description 1',
      location: 'location 1',
      managerId: 'manager 1',
    },
    {
      id: 2,
      orgId: 'org 2',
      wsId: 'ws 2',
      name: 'inventory 2',
      description: 'description 2',
      location: 'location 2',
      managerId: 'manager 2',
    },
    {
      id: 6,
      orgId: 'org 6',
      wsId: 'ws 6',
      name: 'inventory 6',
      description: 'description 6',
      location: 'location 6',
      managerId: 'manager 6',
    },
    {
      id: 2,
      orgId: 'org 2',
      wsId: 'ws 2',
      name: 'inventory 2',
      description: 'description 2',
      location: 'location 2',
      managerId: 'manager 2',
    },
    {
      id: 7,
      orgId: 'org 7',
      wsId: 'ws 7',
      name: 'inventory 7',
      description: 'description 7',
      location: 'location 7',
      managerId: 'manager 7',
    },
    {
      id: 8,
      orgId: 'org 8',
      wsId: 'ws 8',
      name: 'inventory 8',
      description: 'description 8',
      location: 'location 8',
      managerId: 'manager 8',
    },
    {
      id: 9,
      orgId: 'org 9',
      wsId: 'ws 9',
      name: 'inventory 9',
      description: 'description 9',
      location: 'location 9',
      managerId: 'manager 9',
    },
    {
      id: 8,
      orgId: 'org 8',
      wsId: 'ws 8',
      name: 'inventory 8',
      description: 'description 8',
      location: 'location 8',
      managerId: 'manager 8',
    },
    {
      id: 9,
      orgId: 'org 9',
      wsId: 'ws 9',
      name: 'inventory 9',
      description: 'description 9',
      location: 'location 9',
      managerId: 'manager 9',
    },
    {
      id: 10,
      orgId: 'org 10',
      wsId: 'ws 10',
      name: 'inventory 10',
      description: 'description 10',
      location: 'location 10',
      managerId: 'manager 10',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/inventory/inventories').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    brandId = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      user.name.toLowerCase().includes(queryLowered),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
