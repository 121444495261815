import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {
    categories: [],
    metaData: {},
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
    getCategoryMetaData(state) {
      return state.metaData;
    },
    getCategoryLabel(state, categoryId) {
      return (
        state.categories?.find((item) => item.id === categoryId)?.name || ""
      );
    },
  },
  mutations: {
    updateCategories(state, data) {
      state.categories = data || [];
    },
    updateMetaData(state, data) {
      state.metaData = data || {};
    },
  },
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_categories", { params: queryParams })
          .then((response) => {
            ctx.commit("updateCategories", response.data.data);
            ctx.commit("updateMetaData", response.data.page);

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPharmaProduct(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/user/users", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
