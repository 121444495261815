import * as config from "@/utils/config";

// import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios";
import Vue from "vue";

function clearCookie(name, domain, path) {
  var domain = domain || document.domain;
  var path = path || "/";
  document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`;
}
export default {
  namespaced: true,
  state: {
    token: null,
    permissions: [],
    userLogin: {},
    regionsAcept: "",
    regions: "",
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null;
    },
    userLogin(state) {
      return state.userLogin;
    },
    regions(state) {
      return state.regions;
    },
    regionsAcept(state) {
      return state.regionsAcept;
    },
  },
  mutations: {
    clearToken(state) {
      state.token = null;
    },
    setRegionsAcept(state, regionsAcept) {
      state.regionsAcept = regionsAcept;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setUserLogin(state, userLogin) {
      state.userLogin = userLogin;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    clearPermissions(state) {
      state.permissions = [];
    },
  },
  actions: {
    login(ctx, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/login", credentials)
          .then(async (result) => {
            const res = result.data;
            if (res?.code === 200) {
              localStorage.setItem(config.TOKEN_KEY, res?.data?.access_token);
              localStorage.setItem(
                config.TOKEN_EXPIRATION_KEY,
                new Date().getTime() + 3600 * 1000 * 24
              );
              ctx.dispatch("setLogoutTimer", 3600 * 1000 * 24);
              ctx.commit("setToken", res?.data?.access_token);
              const meInfo = await ctx.dispatch("authMe");
              if (meInfo.success) {
                resolve({
                  success: true,
                });
              } else {
                reject(meInfo.message);
              }
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    authMe(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("go/auth/doctor/v2/me")
          .then((result) => {
            const res = result.data;
            if (result.status === 200) {
              const organizations =
                res?.members
                  ?.filter((itemFilter) => [1, 2].includes(itemFilter?.role))
                  ?.map((item) => item?.org) || [];

              if (organizations?.length > 0) {
                const pharmaLocalStorage = JSON.parse(
                  localStorage.getItem("pharmaInfo")
                );

                const data = pharmaLocalStorage || organizations[0];
                localStorage.setItem("pharmaInfo", JSON.stringify(data));

                Vue.prototype.$pharmacyInfo = data;
                localStorage.setItem(config.USER, JSON.stringify(res));
                ctx.commit("setUserLogin", res);
                resolve({
                  success: true,
                });
              } else {
                ctx.dispatch("logout");
                reject({
                  success: false,
                  message: "Bạn không có quyền truy cập vào Pharmacy",
                });
              }
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    async initAuth(ctx) {
      const token = localStorage.getItem(config.TOKEN_KEY);
      const tokenExpiration = localStorage.getItem(config.TOKEN_EXPIRATION_KEY);

      if (!token || !tokenExpiration) {
        return ctx.dispatch("logout");
      }

      if (new Date().getTime() > tokenExpiration || !token) {
        return ctx.dispatch("logout");
      }

      ctx.commit("setToken", token);
      ctx.dispatch("setLogoutTimer", tokenExpiration - new Date().getTime());
      const meInfo = await ctx.dispatch("authMe");

      return meInfo.success;
    },
    setLogoutTimer(ctx, duration) {
      setTimeout(() => {
        ctx.commit("clearToken");
      }, duration);
    },
    logout(ctx) {
      ctx.commit("clearToken");
      sessionStorage.clear();
      localStorage.removeItem(config.TOKEN_KEY);
      localStorage.removeItem(config.TOKEN_EXPIRATION_KEY);
      localStorage.removeItem(config.USER);
      localStorage.removeItem("inventoryInfo");
      localStorage.removeItem("pharmaInfo");
    },
  },
};
