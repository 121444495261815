import axiosLocal from "@/libs/axiosLocal";

export default {
  namespaced: true,
  state: {
    reportType: null,
    reportData: {},
  },
  getters: {},
  mutations: {
    setReportType(state, type) {
      state.reportType = type;
    },
  },
  actions: {
    getReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/core/report/pharmarevenue`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
