import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      name: 'Vitamin 1',
      categoryId: 'cate 1',
      brandId: 'editor',
      sku: 'gslixby0',
      price: '10.000d',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      status: 'inactive',
      avatar: '',
    },
    {
      id: 2,
      name: 'Vitamin 2',
      categoryId: 'cate 2',
      brandId: 'author',
      sku: 'hredmore1',
      price: '20.000d',
      contact: '(472) 607-9137',
      email: 'hredmore1@imgur.com',
      currentPlan: 'team',
      status: 'pending',    },
    {
      id: 3,
      name: 'Vitamin 3',
      categoryId: 'cate 3',
      brandId: 'maintainer',
      sku: 'msicely2',
      price: '30.000d',
      contact: '(321) 264-4599',
      email: 'msicely2@who.int',
      currentPlan: 'enterprise',
      status: 'active',
    },
    {
      id: 4,
      name: 'Vitamin 4',
      categoryId: 'cate 4',
      brandId: 'maintainer',
      sku: 'crisby3',
      price: '40.000d',
      contact: '(923) 690-6806',
      email: 'crisby3@wordpress.com',
      currentPlan: 'team',
      status: 'inactive',
    },
    {
      id: 5,
      name: 'Vitamin 5',
      categoryId: 'cate 5',
      brandId: 'subscriber',
      sku: 'mhurran4',
      price: '50.000d',
      contact: '(669) 914-1078',
      email: 'mhurran4@yahoo.co.jp',
      currentPlan: 'enterprise',
      status: 'pending',
    },
    {
      id: 6,
      name: 'Vitamin 6',
      categoryId: 'cate 6',
      brandId: 'author',
      sku: 'shalstead5',
      price: '60.000d',
      contact: '(958) 973-3093',
      email: 'shalstead5@shinystat.com',
      currentPlan: 'categoryId',
      status: 'active',
      avatar: '',
    },
    {
      id: 7,
      name: 'Vitamin 7',
      categoryId: 'cate 7',
      brandId: 'subscriber',
      sku: 'bgallemore6',
      price: '10.000d',
      contact: '(825) 977-8152',
      email: 'bgallemore6@boston.com',
      currentPlan: 'categoryId',
      status: 'pending',
      avatar: '',
    },
    {
      id: 8,
      name: 'Vitamin 8',
      categoryId: 'cate 8',
      brandId: 'author',
      sku: 'kliger7',
      price: '10.000d',
      contact: '(187) 440-0934',
      email: 'kliger7@vinaora.com',
      currentPlan: 'enterprise',
      status: 'pending',
    },
    {
      id: 9,
      name: 'Vitamin 9',
      categoryId: 'cate 9',
      brandId: 'subscriber',
      sku: 'fscotfurth8',
      price: '10.000d',
      contact: '(978) 146-5443',
      email: 'fscotfurth8@dailymotion.com',
      currentPlan: 'team',
      status: 'pending',
    },
    {
      id: 10,
      name: 'Vitamin 10',
      categoryId: 'cate 10',
      brandId: 'maintainer',
      sku: 'jbellany9',
      price: '50.000d',
      contact: '(589) 284-6732',
      email: 'jbellany9@kickstarter.com',
      currentPlan: 'categoryId',
      status: 'inactive',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/pharmaProduct/products').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    brandId = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.sku.toLowerCase().includes(queryLowered) || user.name.toLowerCase().includes(queryLowered)) &&
      user.brandId === (brandId || user.brandId) &&
      user.currentPlan === (plan || user.currentPlan) &&
      user.status === (status || user.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
