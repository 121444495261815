export const SALE_TYPE = {
  patient: 1,
  customer: 2,
};

export const PAYMENT_TYPE = [
  {
    label: "Chưa thanh toán",
    value: 1,
    class: "text-warning",
  },
  {
    label: "Đã thanh toán",
    value: 2,
    class: "text-success",
  },
  {
    label: "Huỷ phiếu",
    value: 3,
    class: "text-danger",
  },
];

export const SALING_STATUS = {
  notSale: 1,
  sold: 2,
};

export const REPORT_OPTIONS = [
  {
    label: "Báo cáo nhập xuất",
    value: 1,
  },
  {
    label: "Báo cáo tồn kho",
    value: 7,
  },
  // {
  //   label: "Báo cáo thu ngân nhà thuốc",
  //   value: 2,
  // },
  {
    label: "Báo cáo doanh thu nhà thuốc",
    value: 3,
  },
  // {
  //   label: "Báo cáo thuốc nhập nhà cung cấp",
  //   value: 4,
  // },
];

export const UPLOAD_STATUS = [
  {
    value: 1,
    icon: "LoaderIcon",
    class: "upload-loading text-primary",
  },
  {
    value: 2,
    icon: "CheckCircleIcon",
    class: "upload-success text-success",
  },
  {
    value: 3,
    icon: "XCircleIcon",
    class: "upload-success text-danger",
  },
];

export const RECEIVING_TYPE = {
  receiving: 1,
  beginPeriod: 2,
};
