import mock from './mock'

/* eslint-disable import/extensions */

import './data/apps/eCommerce'
import './data/apps/pharmaProduct'
import './data/apps/productBrand'
import './data/apps/productCategory'
import './data/apps/productType'
import './data/apps/inventoryManager'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
