import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPharmaProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/pharmaProduct/products", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProductsV2(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/go/pharmaproducts/search", null, { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSaling(ctx, sale) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_saling", sale)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPrescriptions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/prescription", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getSalingList(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_saling", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getSalingDetail(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_saling/${id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSaling(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_saling/${id}`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createPharmaReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post(`/pharma/pharma_saling`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePaymentSaling(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post(`/pharma/pharma_receipt/paymentstatus`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSalingStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post(`pharma/pharma_saling/status`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getReceipts(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_receipt`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getReceiptDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_receipt/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getCustomerInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`pharma/pharma_customers/phone`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
