import axiosLocal from "@/libs/axiosLocal";

export default {
  namespaced: true,
  state: {
    uploadStatus: null,
    message: {
      success: "",
      error: "",
    },
    metaData: {},
    title: "",
    isProcess: false,
    process: 0,
    totalResponse: {
      number_success: 0,
      number_duplicate: 0,
      number_error: 0,
    },
    dataList: [],
    pharma_id: null,
    callback: null,
  },
  getters: {},
  mutations: {
    updateIsProgress(state, isProcess) {
      state.isProcess = isProcess;
    },
    updateStatusInfo(state, data) {
      state.uploadStatus = data;
    },
    updateMessage(state, message) {
      state.message = message;
    },
    updateMetaData(state, data) {
      state.metaData = data;
    },
    updateTitle(state, title) {
      state.title = title;
    },
    updateProcess(state, process) {
      state.process = process;
    },
    updateTotalResponse(state, data) {
      state.totalResponse = { ...data };
    },
    setDataList(state, data) {
      state.dataList = data;
    },
    setPharmaId(state, id) {
      state.pharma_id = id;
    },
    setCallback(state, callback) {
      state.callback = callback;
    },
  },
  actions: {
    importMedicines(ctx, { data, pharma_id }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma_product/import", { data, pharma_id })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    getProgressUpload(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/progress-task/${id}`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
  },
};
