import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInventories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_invetory/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPharmaProduct(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/user/users", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getWs(ctx, org_id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/core/org/workspace/${org_id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getOrgMembers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/core/org/members`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getWsMembers(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`doctor/clinics/${id}/v2/doctors`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createInventory(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_invetory", params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getInventoryDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_invetory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateInventory(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_invetory/${id}`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteInventory(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_invetory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
