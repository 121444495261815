import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPharmaProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma_product", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPharmaProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma_product", params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getMedicineDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma_product/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateMedicine(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma_product/${id}`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getUseRoute(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_use_route", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    //Unit type
    getUnitTypes(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/product_unit", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createUnitType(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/product_unit", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateUnitType(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/product_unit/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteUnitType(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/product_unit/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Suppliers
    getSuppliers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_suppliers", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createSupplier(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_suppliers", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateSupplier(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_suppliers/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_suppliers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Active ingredients
    getActiveIngredients(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_active_ingredients", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createActiveIngredient(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_active_ingredients", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateActiveIngredient(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_active_ingredients/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteActiveIngredient(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_active_ingredients/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Countries
    getCountries(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_countries", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createCountry(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_countries", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateCountry(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_countries/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCountry(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_countries/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Product_type
    getProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_product_type", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createProductCategories(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_product_type", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateProductCategories(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_product_type/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteProductCategories(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_product_type/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getMedicineCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_medicine_types", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // GroupManagers
    getGroupManagers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_managers", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createGroupManagers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_group_managers", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupManagers(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_group_managers/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupManagers(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_group_managers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // GroupResearchers
    getGroupResearchers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_researchers", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createGroupResearchers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_group_researchers", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupResearchers(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_group_researchers/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupResearchers(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_group_researchers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // GroupPathologicals
    getGroupPathologicals(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_pathologicals", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createGroupPathologicals(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_group_pathologicals", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupPathologicals(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_group_pathologicals/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupPathologicals(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_group_pathologicals/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getGroupABCVEN(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_abcvens", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createGroupABCVEN(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_group_abcvens", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupABCVEN(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_group_abcvens/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupABCVEN(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_group_abcvens/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GroupPharmacologicals
    getGroupPharmacologicals(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_pharmacologicals", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createGroupPharmacologicals(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_group_pharmacologicals", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupPharmacologicals(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_group_pharmacologicals/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupPharmacologicals(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_group_pharmacologicals/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getGroupSubPharmacologicals(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_group_sub_pharmacologicals", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getBiddingTypes(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_bidding_types", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getBiddingForms(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_bidding_forms", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    //Manufacturers
    getManufacturers(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_manufacturers", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createManufacture(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_manufacturers", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateManufacture(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .put(`/pharma/pharma_manufacturers/${id}`, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteManufacture(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma/pharma_manufacturers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getHealthCost(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_health_insurance_cost", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getDilutions(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_dilutions", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getReconstitutions(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_reconstitutions", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePharmaProduct(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .delete(`/pharma_product/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
