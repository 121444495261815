import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dataImport: null,
  },
  getters: {},
  mutations: {
    setDataImport(state, data) {
      state.dataImport = data;
    },
  },
  actions: {
    fetchPharmaProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/pharmaProduct/products", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProductsV2(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/go/pharmaproducts/search", null, { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addReceiving(ctx, receive) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .post("/pharma/pharma_receiving", receive)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getReceivingList(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get("/pharma/pharma_receiving", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getReceivingDetail(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_receiving/${id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
