import axiosLocal from "@/libs/axiosLocal";
import axios from "@axios";

export default {
  namespaced: true,
  state: {
    statusInventoryOptions: [
      {
        label: "Còn hàng",
        value: 3,
      },
      {
        label: "Sắp hết",
        value: 2,
      },
      {
        label: "Đã hết",
        value: 1,
      },
    ],
  },
  getters: {
    statusInventoryOptions: (state) => state.statusInventoryOptions,
  },
  mutations: {},
  actions: {
    fetchUnits(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/go/catalogs/productunit")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBrands(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/go/catalogs/productbrand")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/go/catalogs/pharmacategory")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/go/catalogs/pharmasupplier")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProductsInventory(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/pharma_invetory_product`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProductsSaling(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/go/pharmainventoryproducts")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProductsV2(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/go/pharmaproducts/search", null, { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPharmaProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPharmaProduct(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/user/users", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getPrescriptionWaiting(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosLocal
          .get(`/pharma/prescription/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
